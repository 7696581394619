import requestFunctions from "../../functions/requestFunctions";

export const state = {
  RESULT_LIST: [],
  RESULT_LIST_ROWS: 0
}

export const getters = {
  GET_RESULT_LIST: (state) => {
    return state.RESULT_LIST;
  },
  GET_RESULT_LIST_ROWS: (state) => {
    return state.RESULT_LIST_ROWS;
  },
}

export const mutations = {
  UPDATE_RESULT_LIST(state, list) {

    state.RESULT_LIST = [];
    state.RESULT_LIST_ROWS = 0;

    console.log(list);

    state.RESULT_LIST_ROWS = state.RESULT_LIST_ROWS + list.length;

    let trip_route = list;

    trip_route.forEach(el => {

      let full_direction = [];

      if(el.type === 'train'){
        el.direction.Routes[0].RouteStops.forEach(el => {
          full_direction.push(el.StationName);
        });
        state.RESULT_LIST.push({
          'name': el.info.CarrierDisplayNames[0],
          'number': el.info.TrainNumber,
          'direction': el.direction.Routes[0].OriginName + ' - ' + el.direction.Routes[0].DestinationName,
          'place_start': el.info.OriginName,
          'place_end': el.info.DestinationName,
          'time_start': el.info.DepartureDateTime,
          'time_end': el.info.ArrivalDateTime,
          'time_qnt': el.info.TripDuration,
          'full_direction': full_direction,
          'min_price': 1096
        });
      }

      if(el.type === 'flight'){
        state.RESULT_LIST.push({
          'name': '',
          'number': '',
          'direction': '',
          'place_start': '',
          'place_end': '',
          'time_start': '',
          'time_end': '',
          'time_qnt': '',
          'full_direction': full_direction,
          'min_price': ''
        });
      }

      if(el.type === 'bus'){
        el.direction.RoutePoints.forEach(el => {
          full_direction.push(el.Name);
        });
        state.RESULT_LIST.push({
          'name': '',
          'number': '',
          'direction': el.direction.RoutePoints.RaceName,
          'place_start': '',
          'place_end': '',
          'time_start': '',
          'time_end': '',
          'time_qnt': '',
          'full_direction': full_direction,
          'min_price': ''
        });
      }

      if(el.type === 'boat'){
        state.RESULT_LIST.push({
          'name': '',
          'number': '',
          'direction': '',
          'place_start': '',
          'place_end': '',
          'time_start': '',
          'time_end': '',
          'time_qnt': '',
          'full_direction': full_direction,
          'min_price': ''
        });
      }

    });

  },
}

export const actions = {

  async FETCH_RESULT_LIST({ commit }, payload = {}) {

    let query_url = 'https://api.wenttravel.ru/v1/trips/search';
    let response = await requestFunctions.sendDataAsync(query_url, payload, 'GET');

    console.log(response);

    if(response.status !== 'success'){
      return false;
    } else {
      commit('UPDATE_RESULT_LIST', response.data);
      return response.data;
    }
  },

}