import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)


const routes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../views/dashboard.vue')
  },
  {
    path: '/',
    name: 'Login',
    component: () => import('../views/login.vue')
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('../views/profile.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/register.vue')
  },
  {
    path: '/buy',
    name: 'Buy',
    component: () => import('../views/buy.vue')
  },
  {
    path: '/faq',
    name: 'Faq',
    component: () => import('../views/faq.vue')
  },
  {
    path: '/transactions',
    name: 'Transactions',
    component: () => import('../views/transactions.vue')
  },
  {
    path: '/wallet',
    name: 'Wallet',
    component: () => import('../views/wallet.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
