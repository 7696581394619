<script>
export default {

    components: { },

    data() {
        return {

        }
    },

    watch: {

    },

    computed: {
    },

    async mounted() {

    },

    methods: {

    }
}
</script>


<template>
  <div id="app">
    <router-view/>
  </div>
</template>