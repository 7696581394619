
import axios from 'axios'

let axiosObj = axios.create({
  baseURL: 'https://api.client.za-bota.com'
});

export default {

  async sendDataAsync(requestUrl, params, method = 'POST') {

    let result;
    let requestOptions;

    params.token = window.localStorage.getItem('API_KEY_ZABOTA');

    let formData = new FormData();

    for (let prop in params) {
      if (prop === 'upload_file') {
        formData.append('file', params[prop]);
      } else {
        formData.append(prop, params[prop]);
      }
    }

    if(method == 'POST') {

      requestOptions = {
        headers: {'Content-Type': 'multipart/form-data'}
      };

      console.log(requestUrl);
      console.log(JSON.stringify(requestOptions));

      result = await axiosObj
          .post(requestUrl, formData, requestOptions)
          .then(response => {return response.data})
    }

    if(method == 'PUT') {
      /*
      requestOptions = {
        headers: {'Accept': 'application/json'}
      };
      */
      result = await axiosObj
          .put(requestUrl, params)
          .then(response => {return response.data})

    }

    if (method == 'GET'){

      // преобразуем параметры для добавления
      let urlParams = [];
      if (params != null) {
        for (let itemKey in params) {
          urlParams.push(itemKey + '=' + params[itemKey]);
        }
      }

      // добавляем параметры в запрос
      let urlSuffix = '';
      if(urlParams.length > 0){
        urlSuffix = '?' + urlParams.join('&');
      }

      requestUrl = requestUrl + urlSuffix;
      requestOptions = {
        headers: {'Accept': 'application/json'}
      };

      result = await axiosObj
          .get(requestUrl, requestOptions)
          .then(response => {return response.data})
    }

    if (method == 'DELETE'){

      /*
      requestOptions = {
        headers: {'Accept': 'application/json'},
      }
       */

      // преобразуем параметры для добавления
      let urlParams = [];
      if (params != null) {
        for (let itemKey in params) {
          urlParams.push(itemKey + '=' + params[itemKey]);
        }
      }

      // добавляем параметры в запрос
      let urlSuffix = '';
      if(urlParams.length > 0){
        urlSuffix = '?' + urlParams.join('&');
      }

      requestUrl = requestUrl + urlSuffix;
      
      result = await axiosObj
          .delete(requestUrl, params)
          .then(response => {return response.data})

    }

    return result;

  },
}
